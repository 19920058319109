export default {
  siteTitle: 'Get bonus',
  blogerName: 'BODGIK',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/BODGIKCASINO',
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@bodgik16',
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs.com/c23f8bc5a',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/ca37d3a6d',
      gameTitle: 'Royal High-Road (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs.com/c0c649d93',
      gameTitle: 'All Lucky Clover 5 (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://iz-ir23.com/c2adf2b0e',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/c4db06e5d',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-izpihgrzed.com/cf680d8a7',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-mutlukir.com/c32dc8099',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-irrs01.com/ce8c5f3d0',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-nxoyfjmrn.com/c58d215c0',
      gameTitle: 'Mechanical Clover (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs.com/c0328cea5',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>BODGIK</strong> и получи 100 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'BODGIK',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>100FS</strong><div>За регистрацию по промокоду</div>',
  counter: true,
  bonus100: 'Bônus 100%',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
